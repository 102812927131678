@page { margin: auto;  page-break-inside: avoid; }

@media print {
  .MuiDataGrid-footerContainer {
    display: none !important;
  }
  .MuiDialogContent-root {
    width: 100vw !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .MuiDataGrid-root{
    zoom: 0.5 !important;
    font-size: 20px !important;
    page-break-inside: avoid !important;
  }
}